import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { intRange } from 'utils';

export type YearSelectProps = {
  firstYear: number,
  lastYear: number,
  value: number,
  onChange: (value: number) => void
}

export default function YearSelect(props: YearSelectProps) {
  const years = intRange(props.firstYear, props.lastYear + 1);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(event.target.value);
    props.onChange(value);
  }

  return (
    <TextField label="Pokaż przyszłe zużycie do roku" select value={props.value} onChange={handleChange} fullWidth>
      {years.map(year => 
        <MenuItem value={year} key={year}>{year}</MenuItem>
      )}
    </TextField>
  )
}
