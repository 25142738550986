import * as React from 'react';
import { HourlyData } from 'types';
import { usePrev } from 'app/hooks';
import { HOUR } from 'dataTransformations';
import { Color, Config, Data, Layout, PlotData } from 'plotly.js';
import Plotly from "plotly.js-gl2d-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { useAppDispatch } from 'app/hooks';
import * as State from 'app/state';

const Plot = createPlotlyComponent(Plotly);

const locale = {
  moduleType: "locale",
  name: "pl",
  dictionary: {
      Autoscale: "Skala automatyczna",
      "Box Select": "Zaznaczenie prostokątne",
      "Compare data on hover": "Porównanie danych po najechaniu",
      "Double-click on legend to isolate one trace": "Kliknij dwukrotnie legendę, aby wyizolować serię",
      "Double-click to zoom back out": "Kliknij dwukrotnie, aby oddalić",
      "Download plot as a png": "Pobierz wykres jako PNG",
      "Download plot": "Pobierz wykres",
      "IE only supports svg.  Changing format to svg.": "IE obsługuje tylko SVG. Zmiana formatu na SVG.",
      "Lasso Select": "Zaznaczenie lasso",
      "Orbital rotation": "Rotacja orbitalna",
      Pan: "Przesuwanie",
      Reset: "Resetowanie",
      "Reset axes": "Zresetuj osie",
      "Reset camera to default": "Ustaw kamerę na domyślną",
      "Reset camera to last save": "Ustaw kamerę na zapisaną wartość",
      "Reset view": "Zresetuj widok",
      "Reset views": "Zresetuj widoki",
      "Show closest data on hover": "Pokaż najbliższe dane po najechaniu",
      "Snapshot succeeded": "Konwersja zakończona pomyślnie",
      "Sorry, there was a problem downloading your snapshot!": "Przepraszamy, wystąpił problem podczas pobierania grafiki!",
      "Taking snapshot - this may take a few seconds": "Trwa konwersja, może to potrwać kilka sekund",
      Zoom: "Przybliżanie",
      "Zoom in": "Przybliż",
      "Zoom out": "Odddal",
      trace: "seria",
      "q1:": "q1 :",
      "q3:": "q3 :",
      "source:": "żródło :",
      "target:": "cel :",
      "lower fence:": "dolne ograniczenie :",
      "upper fence:": "górne ograniczenie :",
      "max:": "max. :",
      "mean \xb1 \u03c3:": "średnia \xb1 \u03c3 :",
      "mean:": "średnia :",
      "median:": "mediana :",
      "min:": "min. :",
      "new text": "nowy tekst",
      "Turntable rotation": "Rotacja planarna",
      "Toggle Spike Lines": "Włącz/wyłącz kolce",
      "Toggle show closest data on hover": "Włącz/wyłącz pokazywanie najbliższych danych",
      "incoming flow count:": "przepływ przychodzący :",
      "outgoing flow count:": "przepływ wychodzący :",
  },
  format: {
      days: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
      shortDays: ["Nie", "Pn", "Wt", "Śr", "Czw", "Pt", "So"],
      months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
      shortMonths: ["Sty", "Lu", "Mar", "Kw", "Maj", "Cze", "Lip", "Sie", "Wrz", "Pa", "Lis", "Gru"],
      date: "%d-%m-%Y",
      decimal: ",",
      thousands: " ",
      year: "%Y",
      month: "%b %Y",
      dayMonth: "%-d %b",
      dayMonthYear: "%-d %b %Y"
  }
};

type ChartProps = {
  inputData: HourlyData[],
  predictedData: HourlyData[],
  changedData: HourlyData[],
  limits?: [number, number]
}

function initTrace(name: string, color: Color) {
  return {
    x: [],
    y: [],
    type: 'scattergl',
    mode: 'lines',
    hovertemplate: "%{y:.2f} kWh",
    line: {
      color
    },
    name
  } as Data;
}

function createData(data: HourlyData[]): [x: number[], y: number[]] {
  return [
    data.map(hd => hd.dtime),
    data.map(hd => hd.energy),
  ];
}

const initData = [
  initTrace("Zmienione zużycie", "rgb(50, 255, 50)"),
  initTrace("Dane wejściowe", "rgb(50, 50, 255)"),
  initTrace("Przyszłe zużycie", "rgb(150, 150, 255)")
] as Partial<PlotData>[];

const initLayout = {
  autosize: true,
  xaxis: {
    type: "date",
    tickformatstops: [
      {
        enabled: false,
        dtickrange: [null, HOUR]
      },
      {
        enabled: true,
        dtickrange: [HOUR, null]
      }
    ]
  },
  yaxis: {
    title: "Energia [kWh]",
    fixedrange: true
  },
  showlegend: true,
  legend: {
    xanchor: 'right'
  },
  hovermode: "x unified",
  margin: {
    l: 60,
    r: 50,
    b: 50,
    t: 50,
    pad: 10
  }
} as Partial<Layout>;

const initConfig = {
  // @ts-ignore
  locales: { 'pl': locale },
  locale: 'pl',
  displaylogo: false,
  modeBarButtonsToRemove: ['resetScale2d'],
  scrollZoom: true,
  toImageButtonOptions: {
    format: 'png',
    filename: 'Wykres'
  }
 } as Partial<Config>;

export default function Chart(props: ChartProps) {
  const dataRef = React.useRef(initData);
  const layoutRef = React.useRef(initLayout); 
  const configRef = React.useRef(initConfig);
  const revisionRef = React.useRef(0);

  const dispatch = useAppDispatch();

  function useFigure<T>(update: () => void, prop: T) {
    if (prop !== usePrev(prop)) {
      update();
      revisionRef.current++;
    }
  }
  
  useFigure(() => {
    if (props.limits) {
      layoutRef.current.xaxis!.range = [new Date(props.limits[0]), new Date(props.limits[1])];
      layoutRef.current.xaxis!.autorange = false;
    } else {
      layoutRef.current.xaxis!.autorange = true;
    }
  }, props.limits);
  useFigure(() => [dataRef.current[0].x, dataRef.current[0].y] = createData(props.changedData), props.changedData);
  useFigure(() => [dataRef.current[1].x, dataRef.current[1].y] = createData(props.inputData), props.inputData);
  useFigure(() => [dataRef.current[2].x, dataRef.current[2].y] = createData(props.predictedData), props.predictedData);

  React.useEffect(
    () => {
      dispatch(State.setChangedDataUpdating(false));
    }, 
    [dispatch, props.changedData]
  );

  return (
    <Plot    
      data={dataRef.current}
      layout={layoutRef.current}
      config={configRef.current}

      useResizeHandler={true}
      style={{
        height: "100%",
        width: "100%"
      }}
      revision={revisionRef.current}

      onUpdate={fig => [dataRef.current, layoutRef.current] = [fig.data as Partial<PlotData>[], fig.layout]}
    />
  )
}
