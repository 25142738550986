import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import * as State from 'app/state';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getDay, multsFromProportion } from 'dataTransformations';
import { ChangeView } from 'types';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';


type ChangeItemProps = {
    change: ChangeView,
    index: number,
    onSelect: () => void
}

type ChangesListProps = {
    onSelect: () => void
}

function dateToStr(date: number) {
    return new Date(date).toLocaleDateString();
}

function multToStr(mult: number) {
    return `${Math.round(mult * 100)} %`;
}

function ChangeItem(props: ChangeItemProps) {   
    const dispatch = useAppDispatch();
    const currChangeId = useAppSelector(state => state.currChangeId);
    const [dayEnergy, nightEnergy] = useAppSelector(state => State.selectEnergyByChangeId(state, { changeId: props.index }));
    const minTime = useAppSelector(State.selectMinTime);
    const maxTime = useAppSelector(State.selectMaxTime);

    const minDay = getDay(minTime);
    const maxDay = getDay(maxTime);
    const [dayMult, nightMult] = multsFromProportion(props.change.dayProportion, props.change.multiplier, dayEnergy, nightEnergy);

    return (
        <Paper elevation={0} variant="outlined" square>
            <ListItemButton selected={props.index === currChangeId} onClick={() => {
                dispatch(State.setCurrChangeId(props.index));
                props.onSelect();
            }}>
                <ListItemText 
                    primary={`${dateToStr(props.change.startDate ?? minDay)} - ${dateToStr(props.change.endDate ?? maxDay)}`} 
                    secondary={`Mnożnik dnia: ${multToStr(dayMult)} Mnożnik nocy: ${multToStr(nightMult)}`}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={event => {
                        dispatch(State.removeChange(props.index));
                        event.stopPropagation();
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
        </Paper>
    );
}

export default function ChangesList(props: ChangesListProps) {
    const dispatch = useAppDispatch();

    const changes = useAppSelector(state => state.changes);
    
    return (
        <Paper elevation={3}>
            <List subheader={<ListSubheader>Lista zakresów zmian</ListSubheader>}>
                {changes.map((change, index) => <ChangeItem key={index} change={change} index={index} onSelect={props.onSelect} />)}
                <ListItem>
                    <Button variant="outlined" sx={{width: "100%", height: "100%"}} onClick={() => {
                        dispatch(State.addChange());
                        props.onSelect();
                    }}>
                        Dodaj zakres zmian
                    </Button>
                </ListItem>
            </List>
        </Paper>
    );
}