import * as React from 'react';
import * as State from 'app/state';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DateRange from './DateRange';
import Multipliers from './Multipliers';
import { getDay } from 'dataTransformations';
import Fab from '@mui/material/Fab';
import SyncIcon from '@mui/icons-material/Sync';
import LoadingButton from '@mui/lab/LoadingButton';


export default function ChangeEditor() {
    const dispatch = useAppDispatch();
    const { startDate, endDate } = useAppSelector(State.selectCurrChange);
    const minTime = useAppSelector(State.selectMinTime);
    const maxTime = useAppSelector(State.selectMaxTime);
    const changedDataUpdating = useAppSelector(state => state.changedDataUpdating);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <DateRange startDate={startDate} endDate={endDate} minDate={getDay(minTime)} maxDate={getDay(maxTime)} onDatesChange={(startDate, endDate) => dispatch(State.setDates([startDate, endDate]))} />
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Button variant="contained" onClick={() => dispatch(State.limitChartToDates())}>Pokaż zakres</Button>
            </Grid>
            <Grid item xs={12}>
                <Multipliers/>
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Fab component={LoadingButton} loading={changedDataUpdating} color="primary" variant="extended" onClick={() => dispatch(State.updateChangedData())}>
                    <SyncIcon/>
                    Zaktualizuj wykres
                </Fab>
            </Grid>
        </Grid>
    );
}