import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { TimeUnit } from 'types';

export type UnitSelectProps = {
  value: TimeUnit,
  onUnitChange: (value: TimeUnit) => void
}

export default function UnitSelect(props: UnitSelectProps) {

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onUnitChange(event.target.value as TimeUnit);
  }

  return (
    <TextField label="Jednostka" select value={props.value} onChange={handleChange} fullWidth>
      <MenuItem value="hour">Godzina</MenuItem>
      <MenuItem value="day">Dzień</MenuItem>
    </TextField>
  )
}