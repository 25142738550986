import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

type MultiplierInputProps = {
  value: number,
  label: string,
  adornment: string,
  onChange: (value: number) => void
}

export default function MultiplierInput(props: MultiplierInputProps) {
  const [isEmpty, setIsEmpty] = React.useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(event.target.value);
    
    if (isNaN(value)) {
      if (!isEmpty) {
        setIsEmpty(true);
      }
      props.onChange(0);
    } else {
      if (isEmpty) {
        setIsEmpty(false);
      }
      props.onChange(value);
    }
  }

  if (props.value !== 0 && isEmpty) {
    setIsEmpty(false);
  }

  const value = isNaN(props.value) || isEmpty ? "" : Math.round(props.value);
  
  return (
    <TextField 
      type="number" 
      label={props.label}
      value={value} 
      variant="outlined" 
      InputProps={{
        endAdornment: <InputAdornment position="end">{props.adornment}</InputAdornment>,
      }}
      onChange={handleChange}
    />
  )
}
