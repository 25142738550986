import { HourlyData, Change, TimeUnit } from './types';

export const HOUR = 60 * 60 * 1000;

export function sumDays(data: HourlyData[]) {
    const daysData = data.map((value) => ({ dtime: getDay(value.dtime), energy: value.energy }));
    const resultMap = new Map<number, number>();

    daysData.forEach(value => {
        const currCount = resultMap.get(value.dtime) ?? 0;
        resultMap.set(value.dtime, currCount + value.energy);
    });

    return Array.from(resultMap).map(([dtime, energy]) => ({dtime, energy}));
}

export function getDay(time: number) {
    return new Date(time - 1).setHours(0, 0, 0, 0);
}

function isDayTime(time: number, dayRange: [number, number]) {
  const date = new Date(time);
  const day = date.getDay();

  if (day === 0 || day === 6) {
    return false;
  }

  let hour = new Date(time).getHours();
  if (hour === 0) {
    hour = 24;
  }
  return hour > dayRange[0] && hour <= dayRange[1];
}

export function applyMultipliers(data: HourlyData[], changes: Change[], dayRange: [number, number]) {
  const newChanges = changes.map(({ dayProportion, multiplier, startDate, endDate }) => {
    const [dayEnergy, nightEnergy] = getEnergy(data, startDate, endDate, dayRange);
    const [dayMult, nightMult] = multsFromProportion(dayProportion, multiplier, dayEnergy, nightEnergy);
    return {
      dayMult, 
      nightMult,
      startTime: getDayStart(startDate),
      endTime: getDayEnd(endDate)
    };
  });
  
  return data.map(value => {
    const isDay = isDayTime(value.dtime, dayRange);
    let energy = value.energy;

    for (let change of newChanges) {
      if (value.dtime > change.startTime && value.dtime <= change.endTime) {
        energy *= isDay ? change.dayMult : change.nightMult;
      }
    }

    return {
      dtime: value.dtime,
      energy
    };
  });
}

export function getEnergy(data: HourlyData[], startDate: number | null, endDate: number | null, dayRange: [number, number]): [dayEnergy: number, nightEnergy: number] {
  const startTime = getDayStart(startDate);
  const endTime = getDayEnd(endDate);
  
  let dayEnergy = 0;
  let nightEnergy = 0;

  for (let value of data) {
    if (value.dtime > startTime && value.dtime <= endTime) {
      if (isDayTime(value.dtime, dayRange)) {
        dayEnergy += value.energy;
      }
      else {
        nightEnergy += value.energy;
      }
    }
  }

  return [dayEnergy, nightEnergy];
}

export function getChartData(data: HourlyData[], unit: TimeUnit) {
  switch(unit) {
    case "hour":
      return data;
    case "day":
      return sumDays(data);
  }
}

export function getDayStart(day: number | null) {
  return day !== null ? day : Number.MIN_VALUE
}

export function getDayEnd(day: number | null) {
  return day !== null ? day + 24 * HOUR : Number.MAX_VALUE
}

export function energyFromProportion(dayProportion: number, multiplier: number, energy: number): [newDayEnergy: number, newNightEnergy: number] {
  const newEnergy = energy * multiplier;
  const newDayEnergy = newEnergy * dayProportion;
  const newNightEnergy = newEnergy - newDayEnergy;
  return [newDayEnergy, newNightEnergy];
}

export function multsFromProportion(dayProportion: number, multiplier: number, dayEnergy: number, nightEnergy: number): [dayMult: number, nightMult: number] {
  const [newDayEnergy, newNightEnergy] = energyFromProportion(dayProportion, multiplier, dayEnergy + nightEnergy);
  const dayMult = newDayEnergy / dayEnergy;
  const nightMult = newNightEnergy / nightEnergy;
  return [dayMult, nightMult];
}

export function proportionFromEnergy(newDayEnergy: number, newNightEnergy: number, energy: number): [dayProportion: number, multiplier: number] {
  const dayProportion = newDayEnergy / (newDayEnergy + newNightEnergy);
  const multiplier = (newDayEnergy + newNightEnergy) / energy;
  return [dayProportion, multiplier];
}

export function proportionFromDayEnergy(newDayEnergy: number, dayProportion: number, multiplier: number, proportionLock: boolean, energy: number): [newDayProportion: number, newMultiplier: number] {
  if (proportionLock) {
    const newEnergy = newDayEnergy / dayProportion;
    const newMultiplier = newEnergy / energy;
    return [dayProportion, newMultiplier];
  } else {
    const [, newNightEnergy] = energyFromProportion(dayProportion, multiplier, energy);
    return proportionFromEnergy(newDayEnergy, newNightEnergy, energy);
  }
}

export function proportionFromNightEnergy(newNightEnergy: number, dayProportion: number, multiplier: number, proportionLock: boolean, energy: number): [newDayProportion: number, newMultiplier: number] {
  if (proportionLock) {
    const newEnergy = newNightEnergy / (1 - dayProportion);
    const newMultiplier = newEnergy / energy;
    return [dayProportion, newMultiplier];
  } else {
    const [newDayEnergy, ] = energyFromProportion(dayProportion, multiplier, energy);
    return proportionFromEnergy(newDayEnergy, newNightEnergy, energy);
  }
}