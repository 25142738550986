import { HourlyData } from "types";
import { getDay, HOUR } from "dataTransformations";
import holidays from './holidays.json';

const holidaysSet = new Set(holidays.map(value => getDay(Date.parse(value))));

export class Model {
    table: number[][][];
    prevDay: [dtime: number, iType: number, iDay: number] | undefined;

    constructor(data: HourlyData[]) {
        this.table = createTable(data);
    }

    predict(dtime: number): number {
        const [iType, iHour, iDay] = getDateIds(dtime, this.prevDay);
        this.prevDay = [dtime, iType, iDay];
        return this.table[iType][iHour][iDay];
    }

    predictRange(startTime: number, endTime: number): HourlyData[] {
        const data = new Array<HourlyData>();
        let prevHour = -1;
        for (let dtime = startTime; dtime < endTime; dtime += HOUR) {
            const currHour = new Date(dtime).getHours();
            if (prevHour === currHour) {
                continue;
            } else {
                prevHour = currHour;
            }
            const energy = this.predict(dtime);
            if (Number.isFinite(energy)) {
                data.push({dtime, energy});
            }
        }
        return data;
    }
}

function createTable(data: HourlyData[]): number[][][] {
    const table = new Array<number[][]>(8);
    const yearTable = new Array<number[][]>(8);

    for (let iType = 0; iType < 8; iType++) {
        table[iType] = new Array<number[]>(24);
        yearTable[iType] = new Array<number[]>(24);

        for (let iHour = 0; iHour < 24; iHour++) {
            table[iType][iHour] = new Array<number>(366).fill(Number.NaN);
            yearTable[iType][iHour] = new Array<number>(366).fill(Number.NaN);
        }
    }

    let prevDay: undefined | [dtime: number, iType: number, iDay: number];
    let prevYear: number | undefined;
    let yearStart = -1;

    for (let hd of data) {
        const [iType, iHour, iDay] = getDateIds(hd.dtime, prevDay);
        const year = new Date(hd.dtime - 1).getFullYear();

        if (year !== prevYear) {
            if (prevYear !== undefined && prevDay !== undefined) {
                addYearData(table, yearTable, yearStart, prevDay[2]);
                clearTable(yearTable);
            }
            prevYear = year;
            yearStart = iDay;
        }

        yearTable[iType][iHour][iDay] = hd.energy;
        prevDay = [hd.dtime, iType, iDay];
    }

    if (prevYear !== undefined && prevDay !== undefined) {
        addYearData(table, yearTable, yearStart, prevDay[2]);
    }

    return table;
}

function clearTable(table: number[][][]) {
    for (let iType = 0; iType < 8; iType++) {
        for (let iHour = 0; iHour < 24; iHour++) {
            table[iType][iHour].fill(Number.NaN);
        }
    }
}

function addYearData(table: number[][][], yearTable: number[][][], yearStart: number, yearEnd: number) {
    for (let iType = 0; iType < 8; iType++) {
        for (let iHour = 0; iHour < 24; iHour++) {
            const values = table[iType][iHour];
            const yearValues = fillNotNumber(yearTable[iType][iHour]);

            if (yearValues !== null) {
                for (let i = yearStart; i <= yearEnd; i++) {
                    if (Number.isFinite(values[i])) {
                        values[i] = (values[i] / 3 + yearValues[i] * 2 / 3);
                    } else {
                        values[i] = yearValues[i];
                    }
                }
            }
        }
    }
}

function fillNotNumber(values: number[]) {
    const first = getFirstNumberId(values);
    if (first === null) {
        return null;
    } else {
        let prev = first;
        do {
            const next = getNextNumberId(prev, values);
            const daySpan = (366 + next - prev - 1) % 366 + 1;
            for (let i = 1; i < daySpan; i++) {
                values[(prev + i) % 366] = values[prev] + i / daySpan * (values[next] - values[prev]);
            }
            prev = next;
        } while (prev !== first);
        return values;
    }
}

function getFirstNumberId(values: number[]) {
    for (let i = 0; i < 366; i++) {
        if (Number.isFinite(values[i])) {
            return i;
        }
    }
    return null;
}

function getNextNumberId(prev: number, values: number[]) {
    for (let i = (prev + 1) % 366; ; i = (i + 1) % 366) {
        if (Number.isFinite(values[i])) {
            return i;
        }
    }
}

function getDateIds(dtime: number, prevDay?: [dtime: number, iType: number, iDay: number]): [iType: number, iHour: number, iDay: number] {
    const day = getDay(dtime);
    const iHour = new Date(dtime - 1).getHours();
    let iType, iDay: number;

    if (prevDay !== undefined && getDay(prevDay[0]) === day) {
        [, iType, iDay] = prevDay;
    } else {
        iType = getDayType(day);
        iDay = getDayInYear(day);
    }
    
    return [iType, iHour, iDay];
}

function getDayType(day: number): number {
    if (holidaysSet.has(day)) {
        return 7;
    } else {
        return new Date(day).getDay();
    }
}

function getDayInYear(day: number): number {
    return Math.round((new Date(day).setFullYear(2000) - new Date(2000, 0).getTime()) / (24 * HOUR))
}
