import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createIdSelector } from 'redux-views';
import { HourlyData, TimeUnit, ChangeView } from 'types';
import { getChartData, HOUR, getEnergy, getDay, getDayStart, getDayEnd, proportionFromDayEnergy, proportionFromNightEnergy, multsFromProportion, proportionFromEnergy } from 'dataTransformations';
import { Model } from 'model';
import { AppThunk, RootState } from './store';
import { ModuleThread, spawn, Worker, Pool } from 'threads';
import { DataChangesWorker } from 'workers/dataChanges';
import { QueuedTask } from "threads/dist/master/pool-types";


const dataChangesPool = Pool(() => spawn<DataChangesWorker>(new Worker("../workers/dataChanges")), { concurrency: 1, maxQueuedJobs: 1, size: 1 });
let prevTask: QueuedTask<ModuleThread<DataChangesWorker>, HourlyData[]>;

export interface State {
    inputData: HourlyData[],
    changedData: HourlyData[],
    unit: TimeUnit,
    lastPredictedYear: number,
    chartLimits: [number, number],
    changes: ChangeView[],
    currChangeId: number,
    dayRange: [number, number],
    changedDataUpdating: boolean
}

const initialState: State = {
    inputData: [],
    changedData: [],
    unit: "hour",
    lastPredictedYear: new Date().getFullYear() + 1,
    chartLimits: [new Date(new Date().getFullYear(), 0, 1).getTime(), new Date(new Date().getFullYear() + 1, 0, 1).getTime()],
    changes: [],
    currChangeId: -1,
    dayRange: [6, 22],
    changedDataUpdating: false
};

export const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        _setInputData: (state, action: PayloadAction<HourlyData[]>) => {
            state.inputData = action.payload;
        },
        _setLastPredictedYear: (state, action: PayloadAction<number>) => {
            state.lastPredictedYear = action.payload;
        },
        _setChartLimits: (state, action: PayloadAction<[number, number]>) => {
            state.chartLimits = action.payload;
        },
        _setMults: (state, action: PayloadAction<[dayProportion: number | undefined, multiplier: number | undefined]>) => {
            const change = state.changes[state.currChangeId];
            const [dayProportion, multiplier] = action.payload;

            if (dayProportion !== undefined && Number.isFinite(dayProportion)) {
                if (dayProportion < 0) {
                    change.dayProportion = 0;
                } else if (dayProportion > 1) {
                    change.dayProportion = 1;
                } else {
                    change.dayProportion = dayProportion;
                }
            }
            
            if (multiplier !== undefined && Number.isFinite(multiplier)) {
                if (multiplier < 0) {
                    change.multiplier = 0;
                } else {
                    change.multiplier = multiplier;
                }
            }
        },
        _addChange: (state, action: PayloadAction<ChangeView>) => {
            state.changes.push(action.payload);
            state.currChangeId = state.changes.length - 1;
        },
        _setDayRange: (state, action: PayloadAction<[number, number]>) => {
            state.dayRange = action.payload;
        },
        _setChanges: (state, action: PayloadAction<ChangeView[]>) => {
            state.changes = action.payload;
            state.currChangeId = Math.min(state.currChangeId, action.payload.length - 1);
        },
        setChangedData: (state, action: PayloadAction<HourlyData[]>) => {
            if (state.changedDataUpdating) {
                state.changedData = action.payload;
            }
        },
        setChangedDataUpdating: (state, action: PayloadAction<boolean>) => {
            state.changedDataUpdating = action.payload;
        },
        setUnit: (state, action: PayloadAction<TimeUnit>) => {
            state.unit = action.payload;
        },
        setDates: (state, action: PayloadAction<[startDate: number | null, endDate: number | null]>) => {
            const change = state.changes[state.currChangeId];
            change.startDate = action.payload[0];
            change.endDate = action.payload[1];
        },
        setProportionLock: (state, action: PayloadAction<boolean>) => {
            const change = state.changes[state.currChangeId];
            change.proportionLock = action.payload;
        },
        setCurrChangeId: (state, action: PayloadAction<number>) => {
            state.currChangeId = action.payload;
        },
        removeChange: (state, action: PayloadAction<number>) => {
            state.changes.splice(action.payload, 1);
            if (state.currChangeId >= state.changes.length) {
                state.currChangeId = state.changes.length - 1;
            }
        },
        restart: (state) => {
            state.inputData = initialState.inputData;
            state.changedData = initialState.changedData;
            state.unit = initialState.unit;
            state.lastPredictedYear = initialState.lastPredictedYear;
            state.chartLimits = initialState.chartLimits;
            state.changes = initialState.changes;
            state.currChangeId = initialState.currChangeId;
            state.dayRange = initialState.dayRange;
            state.changedDataUpdating = initialState.changedDataUpdating;
        }
    }
});

export const { 
    setChangedData,
    setChangedDataUpdating,
    setUnit, 
    setDates, 
    setProportionLock,
    setCurrChangeId,
    removeChange,
    restart 
} = stateSlice.actions;

export const setInputData = (data: HourlyData[]): AppThunk => (dispatch, getState) => {
    let state: RootState;

    dispatch(stateSlice.actions._setInputData(data));

    state = getState();
    const minPredictedYear = selectMinPredictedYear(state);

    dispatch(stateSlice.actions._setLastPredictedYear(minPredictedYear + 1));
    dispatch(updateChangedData());
    dispatch(limitChartToData());
};

export const setLastPredictedYear = (year: number): AppThunk => (dispatch, getState) => {
    dispatch(stateSlice.actions._setLastPredictedYear(year));
    dispatch(updateChangedData());
    dispatch(limitChartToData());
};

export const limitChartToData = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const minChartTick = selectMinChartTick(state);
    const maxChartTick = selectMaxChartTick(state);
    dispatch(stateSlice.actions._setChartLimits([minChartTick, maxChartTick]));    
};

export const limitChartToDates = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const change = selectCurrChange(state);
    const startDate = change.startDate;
    const startTime = startDate ? getDayStart(startDate) : selectMinChartTick(state);
    const endDate = change.endDate;
    const endTime = endDate ? getDayEnd(endDate) : selectMaxChartTick(state);
    dispatch(stateSlice.actions._setChartLimits([startTime, endTime]));
};

export const setDayMult = (value: number): AppThunk => (dispatch, getState) => {
    const state = getState();
    const change = selectCurrChange(state);
    const [dayEnergy, nightEnergy] = selectCurrEnergy(state);
    const newDayEnergy = value * dayEnergy;
    const mults = proportionFromDayEnergy(newDayEnergy, change.dayProportion, change.multiplier, change.proportionLock, dayEnergy + nightEnergy);
    dispatch(stateSlice.actions._setMults(mults));
};

export const setNightMult = (value: number): AppThunk => (dispatch, getState) => {
    const state = getState();
    const change = selectCurrChange(state);
    const [dayEnergy, nightEnergy] = selectCurrEnergy(state);
    const newNightEnergy = value * nightEnergy;
    const mults = proportionFromNightEnergy(newNightEnergy, change.dayProportion, change.multiplier, change.proportionLock, dayEnergy + nightEnergy);
    dispatch(stateSlice.actions._setMults(mults));
};

export const setDayEnergy = (value: number): AppThunk => (dispatch, getState) => {
    const state = getState();
    const change = selectCurrChange(state);
    const [dayEnergy, nightEnergy] = selectCurrEnergy(state);
    const mults = proportionFromDayEnergy(value, change.dayProportion, change.multiplier, change.proportionLock, dayEnergy + nightEnergy);
    dispatch(stateSlice.actions._setMults(mults));
};

export const setNightEnergy = (value: number): AppThunk => (dispatch, getState) => {
    const state = getState();
    const change = selectCurrChange(state);
    const [dayEnergy, nightEnergy] = selectCurrEnergy(state);
    const mults = proportionFromNightEnergy(value, change.dayProportion, change.multiplier, change.proportionLock, dayEnergy + nightEnergy);
    dispatch(stateSlice.actions._setMults(mults));
};

export const addChange = (): AppThunk => (dispatch, getState) => {
    let state = getState();
    const minTime = selectMinTime(state);
    const maxTime = selectMaxTime(state);
    const change: ChangeView = {
        startDate: getDay(minTime),
        endDate: getDay(maxTime),
        dayProportion: 0.5,
        multiplier: 1,
        proportionLock: false
    };

    dispatch(stateSlice.actions._addChange(change));

    state = getState();
    const [dayEnergy, nightEnergy] = selectCurrEnergy(state);
    const dayProportion = dayEnergy / (dayEnergy + nightEnergy);

    dispatch(setDayProportion(dayProportion));
}

export const updateChangedData = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const allData = selectAllData(state);

    if (prevTask !== undefined) {
        prevTask.cancel();
    }

    dispatch(setChangedDataUpdating(true));

    prevTask = dataChangesPool.queue(async worker => {
        const changedData = await worker.applyMultipliers(allData, state.changes, state.dayRange);
        dispatch(setChangedData(changedData));
    });
};

export const setDayRange = (value: [number, number]): AppThunk => (dispatch, getState) => {
    let state = getState();
    const multsList = state.changes.map((change, index) => {
        const [dayEnergy, nightEnergy] = selectEnergyByChangeId(state, { changeId: index });
        return multsFromProportion(change.dayProportion, change.multiplier, dayEnergy, nightEnergy);
    });

    dispatch(stateSlice.actions._setDayRange(value));

    state = getState();
    const newChanges = multsList.map(([dayMult, nightMult], index) => {
        const [dayEnergy, nightEnergy] = selectEnergyByChangeId(state, { changeId: index });
        const change = state.changes[index];
        const newDayEnergy = dayEnergy * dayMult;
        const newNightEnergy = nightEnergy * nightMult;
        const newChange: ChangeView = { ...change };
        [newChange.dayProportion, newChange.multiplier] = proportionFromEnergy(newDayEnergy, newNightEnergy, dayEnergy + nightEnergy);
        return newChange;
    });

    dispatch(stateSlice.actions._setChanges(newChanges));
    dispatch(updateChangedData());
};

export const setDayProportion = (value: number): AppThunk => (dispatch, getState) => {
    dispatch(stateSlice.actions._setMults([value, undefined]));
};

export const selectChartInputData = createSelector([(s: RootState) => s.inputData, (s: RootState) => s.unit], getChartData);
export const selectChartChangedData = createSelector([(s: RootState) => s.changedData, (s: RootState) => s.unit], getChartData);

export const selectMinTime = (s: RootState) => s.inputData[0]?.dtime ?? new Date().setMinutes(0, 0, 0);
export const selectMaxInputTime = (s: RootState) => s.inputData[s.inputData.length - 1]?.dtime ?? new Date().setMinutes(0, 0, 0);

export const selectMinPredictedYear = (s: RootState) => Math.max(new Date(selectMaxInputTime(s)).getFullYear(), new Date().getFullYear())
export const selectMaxPredictedYear = (s: RootState) => selectMinPredictedYear(s) + 8
export const selectModel = createSelector([(s: RootState) => s.inputData], inputData => new Model(inputData));
export const selectPredictedData = createSelector(
    [(s: RootState) => s.lastPredictedYear, selectModel, selectMaxInputTime], 
    (lastPredictedYear, model, maxInputTime) => model.predictRange(maxInputTime + HOUR, new Date(lastPredictedYear + 1, 0, 1).getTime() + HOUR)
);

export const selectMaxTime = (s: RootState) => {
    const predictedData = selectPredictedData(s);
    return predictedData[predictedData.length - 1]?.dtime ?? new Date().setMinutes(0, 0, 0);
};
export const selectMinChartTick = (s: RootState) => s.unit === "day" ? getDay(selectMinTime(s)) : selectMinTime(s);
export const selectMaxChartTick = (s: RootState) => s.unit === "day" ? getDay(selectMaxTime(s)) : selectMaxTime(s);

export const selectChartPredictedData = createSelector([selectPredictedData, (s: RootState) => s.unit], getChartData);
export const selectAllData = createSelector([(s: RootState) => s.inputData, selectPredictedData], (inputData, predictedData) => inputData.concat(predictedData));

const selectChangeIdProp = createIdSelector((props: { changeId: number }) => props.changeId.toString());
export const selectStartDateByChangeId = createSelector([(s: RootState) => s.changes, selectChangeIdProp], (changes, changeId) => changes[parseInt(changeId)]?.startDate ?? null);
export const selectEndDateByChangeId = createSelector([(s: RootState) => s.changes, selectChangeIdProp], (changes, changeId) => changes[parseInt(changeId)]?.endDate ?? null);
export const selectEnergyByChangeId = createSelector([selectAllData, selectStartDateByChangeId, selectEndDateByChangeId, (s: RootState) => s.dayRange], getEnergy);

export const selectCurrChange = (s: RootState) => s.changes[s.currChangeId] ?? null;
export const selectCurrEnergy = (s: RootState) => selectEnergyByChangeId(s, { changeId: s.currChangeId });

export default stateSlice.reducer;
