import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { pl } from 'date-fns/locale';

type DateInputProps = {
  label: string,
  value: number | null,
  minDate: number,
  maxDate: number,
  onChange: (value: number | null) => void
}

type DateRangeProps = {
  startDate: number | null,
  endDate: number | null,
  minDate: number,
  maxDate: number,
  onDatesChange: (startDate: number | null, endDate: number | null) => void
};

function DateInput(props: DateInputProps) {
  return (
    <DatePicker
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      renderInput={(params) => <TextField {...params} />}
      inputFormat="dd.MM.yyyy"
      mask="__.__.____"
      minDate={props.minDate}
      maxDate={props.maxDate}
      allowSameDateSelection
      views={["year", "month", "day"]}
    />
  )
}

export default function DateRange(props: DateRangeProps) {

  function handleStartDateChange(date: Date | number | null) {
    const newDate = date !== null ? new Date(date).setHours(0,0,0,0) : null;
    props.onDatesChange(newDate, props.endDate);
  }

  function handleEndDateChange(date: Date | number | null) {
    const newDate = date !== null ? new Date(date).setHours(0,0,0,0) : null;
    props.onDatesChange(props.startDate, newDate);
  }

  const maxStartDate = Math.min(props.maxDate, props.endDate ?? Number.MAX_VALUE);
  const minEndDate = Math.max(props.minDate, props.startDate ?? Number.MIN_VALUE);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
      <Stack direction="row" spacing={3} justifyContent="space-around">
        <DateInput label="Początek zakresu" value={props.startDate} onChange={handleStartDateChange} minDate={props.minDate} maxDate={maxStartDate} />
        <DateInput label="Koniec zakresu" value={props.endDate} onChange={handleEndDateChange} minDate={minEndDate} maxDate={props.maxDate} />
      </Stack>
    </LocalizationProvider>
  );
}
