import * as React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { DEFAULT_DEBOUNCE } from 'utils';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function usePrev<T>(value: T): T | undefined {
    const ref = React.useRef<T>();
    const result = ref.current;
    ref.current = value;
    return result;
}

export function useDebounce<T extends any[]>(func: (...args: T) => void, timeout: number = DEFAULT_DEBOUNCE) {
    const timerRef = React.useRef<NodeJS.Timeout>();
    return (...args: T) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => func(...args), timeout);
    };
}
