import * as React from 'react';
import { DropzoneArea, AlertType } from 'react-mui-dropzone';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import * as api from 'api';
import { download } from 'utils';
import { HourlyData } from 'types';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';


type UploadDialogProps = {
    open: boolean,
    loading: boolean,
    onLoading: (promise: Promise<HourlyData[]>) => void
};

const acceptedFiles = [".xls", ".xlsx"];

export default function UploadDialog(props: UploadDialogProps) {
    const [files, setFiles] = React.useState<File[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        loadData(e.target as HTMLFormElement);
    };

    const loadData = async (form: HTMLFormElement) => {
        let data = new FormData(form);
        files.forEach(file => {
            data.append("workbook", file);
        });

        props.onLoading(api.loadWorkbooks(data));
    }

    const handleAlert = (message: string, variant: AlertType) => {
        if (variant === "error") {
            console.log(message);
            enqueueSnackbar(message, { variant: "error" });
        }
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>Dodaj arkusze z danymi</DialogTitle>
            <DialogContent>
                <form id="profile-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DropzoneArea
                                dropzoneProps={{ disabled: props.loading }}
                                previewChipProps={{ disabled: props.loading }}
                                showPreviewsInDropzone={true}
                                showFileNames={true}
                                showPreviews={false}
                                useChipsForPreview={true}
                                maxFileSize={10000000}
                                dropzoneText={"Upuść plik lub kliknij"}
                                acceptedFiles={acceptedFiles}
                                onChange={setFiles}
                                filesLimit={10}
                                showAlerts={false}
                                previewGridProps={{ container: { justifyContent: "space-evenly" } }}
                                onAlert={handleAlert}
                                getFileLimitExceedMessage={filesLimit => `Maksymalna dozwolona liczba plików: ${filesLimit}`}
                                getDropRejectMessage={rejectedFile => `Plik ${rejectedFile.name} został odrzucony`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Dozwolone typy plików: {acceptedFiles.join(", ")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button variant="outlined" onClick={() => download("Przykładowe dane.xlsx")}>
                                <DownloadIcon />
                                Pobierz przykład prezentujący format danych
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth id="PPECode" label="PPE" name="PPECode" autoFocus disabled={props.loading} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth id="customerType" label="Rodzaj działalności" name="customerType" disabled={props.loading} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth id="TG" label="Grupa taryfowa" name="TG" disabled={props.loading} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth id="OSDName" label="OSD" name="OSDName" disabled={props.loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField select defaultValue={"true"} id="isIntake" name="isIntake" fullWidth disabled={props.loading}>
                                <MenuItem value="true">Energia pobrana</MenuItem>
                                <MenuItem value="false">Energia wytworzona</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="justify" fontSize="0.75rem">
                                Dane procesowane na stronie Databridge.pl są w formie zanonimizowanej poddawane dalszej analizie.
                                Jej celem jest opracowanie rozwiązań obniżających koszty funkcjonowania rynku.
                                Wczytane dane nie będę prezentowane publicznie w żadnej formie.
                                Wczytując dane wyrażamy zgodę na wykorzystanie ich do badań naukowych.
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={props.loading} variant="contained" type="submit" form="profile-form">Dodaj</LoadingButton>
            </DialogActions>
        </Dialog >
    )
}