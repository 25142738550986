import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import * as State from 'app/state';
import DayTimeSlider from './PeakTimeSlider';


type PeakZoneDialogProps = {
    open: boolean
    onClose: () => void
}

export default function PeakZoneDialog(props: PeakZoneDialogProps) {
    const dispatch = useAppDispatch();
    const dayRange = useAppSelector(state => state.dayRange);
    const [value, setValue] = React.useState<[number, number]>(dayRange);

    React.useEffect(() => setValue(dayRange), [dayRange]);

    const handleSave = () => {
        dispatch(State.setDayRange(value));
        props.onClose();
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle>Strefa szczytowa</DialogTitle>
            <DialogContent>
                <DayTimeSlider value={value} onChange={setValue} />
                <Typography align="justify">
                    Za strefę szczytową przyjęty zostaje zakres czasu zaznaczony na osi w dni od poniedziałku do piątku.
                    Pozostały zakres czasu od poniedziałku do piątku oraz weekendy traktowane są jako strefa pozaszczytowa.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSave}>
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    );
}
