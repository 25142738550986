import * as React from 'react';
import MultiplierInput from './MultiplierInput';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import * as State from 'app/state';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { energyFromProportion } from 'dataTransformations';
import Typography from '@mui/material/Typography';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import IconButton from '@mui/material/IconButton';


export default function Multipliers() {
  const dispatch = useAppDispatch();
  const { dayProportion, multiplier, proportionLock } = useAppSelector(State.selectCurrChange);
  const [dayEnergy, nightEnergy] = useAppSelector(State.selectCurrEnergy);

  const [newDayEnergy, newNightEnergy] = energyFromProportion(dayProportion, multiplier, dayEnergy + nightEnergy);
  const lockDisabled = dayProportion === 0 || dayProportion === 1;
  const sliderValue = dayProportion * 100;

  function handleSliderChange(value: number) {
    dispatch(State.setDayProportion(value / 100));
  }

  function handleCheckboxChange(event: React.ChangeEvent, checked: boolean) {
    dispatch(State.setProportionLock(checked));
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <MultiplierInput value={newDayEnergy / dayEnergy * 100} onChange={value => dispatch(State.setDayMult(value / 100))} label="Mnożnik szczytowy" adornment="%" />
      </Grid>
      <Grid item xs={6}>
        <MultiplierInput value={newNightEnergy / nightEnergy * 100} onChange={value => dispatch(State.setNightMult(value / 100))} label="Mnożnik pozaszczytowy" adornment="%" />
      </Grid>
      <Grid item xs={6}>
        <MultiplierInput value={newDayEnergy} onChange={value => dispatch(State.setDayEnergy(value))} label="Energia szczytowa" adornment="kWh" />
      </Grid>
      <Grid item xs={6}>
        <MultiplierInput value={newNightEnergy} onChange={value => dispatch(State.setNightEnergy(value))} label="Energia pozaszczytowa" adornment="kWh" />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel control={<Checkbox checked={proportionLock} onChange={handleCheckboxChange} disabled={lockDisabled} />} label="Zablokuj proporcje" />
      </Grid>
      <Grid item xs={12}>
        <Typography align="justify">
          Przenoszenie wolumenu między strefami:
        </Typography>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs="auto" component={IconButton} onClick={() => handleSliderChange(sliderValue + 0.1)} disabled={proportionLock}>
            <KeyboardDoubleArrowUpIcon fontSize="large" color="primary" />
          </Grid>
          <Grid item xs={true} paddingX={1}>
            <Slider track={false} disabled={proportionLock} value={sliderValue} onChange={(_, value) => handleSliderChange(value as number)} step={0.1} />
          </Grid>
          <Grid item xs="auto" component={IconButton} onClick={() => handleSliderChange(sliderValue - 0.1)} disabled={proportionLock}>
            <KeyboardDoubleArrowDownIcon fontSize="large" color="primary" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}