export const DEFAULT_DEBOUNCE = 300;

export function debounce<T extends any[]>(func: (...args: T) => void, timeout: number = DEFAULT_DEBOUNCE) {
  let timer: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), timeout);
  };
}

export function download(url: string, filename?: string) {
  const link = document.createElement('a');
  link.href = url;
  if (filename) {
    link.download = filename;
  }
  link.click();
}

export function intRange(start: number, end: number): number[] {
  const result = new Array<number>(end - start);
  for (let i = start; i < end; i++) {
    result[i - start] = i;
  }
  return result;
}

export function min(array: number[]): number {
  return array.reduce((prev, curr) => Math.min(prev, curr), Math.min());
}

export function max(array: number[]): number {
  return array.reduce((prev, curr) => Math.max(prev, curr), Math.max());
}


export function checkTime<T extends () => any>(func: T, label: string) {
  const startTime = Date.now();
  const result = func();
  const endTime = Date.now();
  console.log(`${label} time: ${endTime - startTime} ms`);
  return result;
}

export async function checkTimeAsync<T extends () => Promise<any>>(func: T, label: string) {
  const startTime = Date.now();
  const result = await func();
  const endTime = Date.now();
  console.log(`${label} time: ${endTime - startTime} ms`);
  return result;
}
