import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import UnitSelect from './UnitSelect';
import YearSelect from './YearSelect';
import ChangesList from './ChangesList';
import PeakZoneDialog from './PeakZoneDialog';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import * as State from 'app/state';


type MainPanelProps = {
    onSelect: () => void
}

export default function MainPanel(props: MainPanelProps) {
    const dispatch = useAppDispatch();
    const unit = useAppSelector(state => state.unit);
    const lastPredictedYear = useAppSelector(state => state.lastPredictedYear);
    const minPredictedYear = useAppSelector(State.selectMinPredictedYear);
    const maxPredictedYear = useAppSelector(State.selectMaxPredictedYear);

    const [peakZoneOpen, setPeakZoneOpen] = React.useState<boolean>(false);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <UnitSelect value={unit} onUnitChange={unit => dispatch(State.setUnit(unit))} />
            </Grid>
            <Grid item xs={12}>
                <YearSelect firstYear={minPredictedYear} lastYear={maxPredictedYear} value={lastPredictedYear} onChange={year => dispatch(State.setLastPredictedYear(year))} />
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Button variant="contained" onClick={() => setPeakZoneOpen(true)}>
                    Ustaw strefę szczytową
                </Button>
                <PeakZoneDialog  open={peakZoneOpen} onClose={() => setPeakZoneOpen(false)}/>
            </Grid>
            <Grid item xs={12}>
                <ChangesList onSelect={props.onSelect} />
            </Grid>
        </Grid>
    );
}