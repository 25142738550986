import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Chart from './components/Chart';
import UploadDialog from './components/UploadDialog';
import { HourlyData } from 'types';
import ChangeEditor from './components/ChangeEditor';
import MainPanel from './components/MainPanel';
import { useSnackbar } from 'notistack';
import * as api from 'api';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import * as State from 'app/state';
import { download } from 'utils';


type TabsValue = "main" | "change-editor";


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.databridge.pl">
        Databridge
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  const [uploadOpen, setUploadOpen] = React.useState<boolean>(true);
  const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);
  const [tabsValue, setTabsValue] = React.useState<TabsValue>("main");

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const changedData = useAppSelector(state => state.changedData);
  const chartLimits = useAppSelector(state => state.chartLimits);
  const changes = useAppSelector(state => state.changes);
  const chartInputData = useAppSelector(State.selectChartInputData);
  const chartPredictedData = useAppSelector(State.selectChartPredictedData);
  const chartChangedData = useAppSelector(State.selectChartChangedData);

  const editorVisible = changes.length > 0;

  const handleRestart = () => {
    dispatch(State.restart());
    setUploadOpen(true);
    setDownloadLoading(false);
  };

  async function handleDownload() {
    try {
      setDownloadLoading(true);
      const blob = await api.generateWorkbook(changedData);
      const blobUrl = URL.createObjectURL(blob);
      download(blobUrl, "Nowe dane.xlsx");
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      enqueueSnackbar("Błąd pobierania danych", { variant: "error" });
    } finally {
      setDownloadLoading(false);
    }
  };

  async function handleDataLoading(dataPromise: Promise<HourlyData[]>) {
    setUploadLoading(true);
    try {
      const data = (await dataPromise).sort((a, b) => a.dtime - b.dtime);
      dispatch(State.setInputData(data));
      setUploadOpen(false);
    } catch (error) {
      let msg = "Błąd pobierania danych";

      if (error instanceof Error && error.message !== "") {
        msg = `${msg} - ${error.message}`;
      };

      enqueueSnackbar(msg, { variant: "error" });
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <>
      <UploadDialog loading={uploadLoading} open={uploadOpen} onLoading={handleDataLoading} />
      <Box component="main" height="100vh" display="flex">
        <Box pr={2} flex={1} minWidth={300}>
          <Chart inputData={chartInputData} predictedData={chartPredictedData} changedData={chartChangedData} limits={chartLimits} />
        </Box>
        <Box minWidth={450} width={450} component={Paper} elevation={6} square>
          <TabContext value={editorVisible ? tabsValue : "main"}>
            <Grid container direction="column" height="100%">
              <Grid item borderBottom={1} borderColor="divider">
                <TabList centered onChange={(_, value) => setTabsValue(value)}>
                  <Tab label="Panel główny" value="main" />
                  {editorVisible &&
                    <Tab label="Edycja zakresu zmian" value="change-editor" />
                  }
                </TabList>
              </Grid>
              <Grid item flex={1} style={{overflowY: "auto"}}>
                <Grid container justifyContent="space-between" height="100%" direction="column" flexWrap="nowrap">
                  <Grid item>
                    <TabPanel value="main">
                      <MainPanel onSelect={() => setTabsValue("change-editor")} />
                    </TabPanel>
                    {editorVisible && (
                      <TabPanel value="change-editor">
                        <ChangeEditor />
                      </TabPanel>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} justifyContent="center" direction="row">
                      <Grid item>
                        <Fab component={LoadingButton} loading={downloadLoading} color="primary" variant="extended" onClick={handleDownload}>
                          <DownloadIcon />
                          Pobierz zmiany
                        </Fab>
                      </Grid>
                      <Grid item>
                        <Fab color="primary" variant="extended" onClick={handleRestart}>
                          <RestartAltIcon />
                          Od nowa
                        </Fab>
                      </Grid>
                      <Grid item xs={12}>
                        <Copyright />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabContext>
        </Box>
      </Box>
    </>
  );
}