import { HourlyData } from './types'
import format from 'date-fns/format'

const uri = process.env.REACT_APP_API_URI as string;

export async function loadWorkbooks(data: FormData): Promise<HourlyData[]> {
    const response = await fetch(uri + "LoadWorkbook", {
        method: "POST",
        body: data
    });

    const content = await response.text();

    if (!response.ok) {
        throw new Error(content);
    }

    return JSON.parse(content, (key, value) => {
        if (key === "dtime") {
            return new Date(value).getTime();
        } else {
            return value;
        }
    });
}

export async function generateWorkbook(data: HourlyData[]) {     
    const response = await fetch(uri + "GenerateWorkbook", {
        method: "POST",
        body: JSON.stringify(data.map(({dtime, energy}) => ({dtime: format(dtime, "yyyy-MM-dd'T'HH:mm:ss"), energy})))
    });

    if (!response.ok) {
        throw new Error(await response.text());
    }

    return await response.blob();
}
