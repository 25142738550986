import * as React from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';


type DayTimeSliderProps = {
    value: [number, number],
    onChange: (value: [number, number]) => void
}

export default function DayTimeSlider(props: DayTimeSliderProps) {
    return (
        <Box sx={{ paddingX: 1 }}>
            <Slider
                min={0}
                max={24}
                marks
                valueLabelDisplay="on"
                valueLabelFormat={x => `${x}:00`}
                value={props.value}
                onChange={(_, value) => props.onChange(value as [number, number])}
                sx={{ marginTop: 4 }}
            />
        </Box>
    );
}
